import { Button, DatePicker, Form, InputNumber, Select, Space, Typography, Row, Col } from "antd";
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from '../../context/AppContext';
import { dateFormat, formatNumber } from '../../shared/shared-functions';
import { useHttpService } from "../../hooks/UseHttpService";
//TODO (LJ): this should come from DB
const cbsaCodes = [
  { label: 'Brigham City, UT', value: '14940' },
  { label: 'Cedar City, UT', value: '16260' },
  { label: 'Heber, UT', value: '25720' },
  { label: 'Logan, UT-ID', value: '30860' },
  { label: 'Ogden-Clearfield, UT', value: '36260' },
  { label: 'Price, UT', value: '39220' },
  { label: 'Provo-Orem, UT', value: '39340' },
  { label: 'St. George, UT', value: '41100' },
  { label: 'Salt Lake City, UT', value: '41620' },
  { label: 'Vernal, UT', value: '46860' }
];

const ValueEstimatorView: React.FC = () => {

  const { isLoggedIn, redirectIfNoUser } = useAppContext();

  const http = useHttpService();

  const [estimate, setEstimate] = useState(null);

  useEffect(() => {
      redirectIfNoUser();
  }, [isLoggedIn]);

  const onFinish = async ({ metroArea, appraisalValue, appraisalDate, projectionDate }) => {
    const result: any = await http.get(`/projectedValue?cbsaCode=${encodeURIComponent(metroArea)}&appraisalDate=${encodeURIComponent(appraisalDate.format(dateFormat))}&appraisalValue=${encodeURIComponent(appraisalValue)}&projectionDate=${encodeURIComponent(projectionDate.format(dateFormat))}`);
    if (!!!result.success) {
      toast(result.message, { type: "error", toastId: "valueEstimateError" });
      return;
    }
    setEstimate(result.data?.projectedValue)
  }

  return (
    <div style={{width: '100%', display: 'grid', placeItems: 'center', marginTop: 20}}>
        <Space style={{ maxWidth: 600, width: "100%", maxHeight:"100%"}} direction="vertical">
          <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Metro Area"
            name="metroArea"
            rules={[{ required: true, message: 'Please select a metro area' }]}
          >
            <Select
              placeholder="Select a metro area"
              options={cbsaCodes}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Row gutter={8}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Appraisal Date"
                name="appraisalDate"
                rules={[{ required: true, message: 'Please select an appraisal date' }]}
              >
                <DatePicker 
                  format={dateFormat} 
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
            
              <Form.Item
                label="Appraisal Value"
                name="appraisalValue"
                rules={[{ required: true, message: 'Please enter the appraisal value' }]}
              >
                <InputNumber<number>
                  style={{ width: '100%' }}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                  min={0}
                />
              </Form.Item>

            </Col>
          </Row>

          <Form.Item
            label="Projection Date"
            name="projectionDate"
            rules={[{ required: true, message: 'Please select a projection date' }]}
          >
            <DatePicker
              format={dateFormat}
              style={{ width: '100%', maxWidth: '300px' }}
              popupStyle={{ overflow: "auto" }}
              placement="bottomLeft"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
        <Typography.Title level={4} style={{ marginTop: 20 }}>
          {estimate !== null ? `Projected value: $${formatNumber(estimate)}` : ""}
        </Typography.Title>
      </Space>
    </div>
  );
};

export default function ValueEstimator() {
  return (
    <ValueEstimatorView />
  )
}
