import { Typography } from "antd";
import { MarketConditionsInfo } from "../../../types/MarketConditionsInfo.def";

export const Forcast = (marketConditionInfo: MarketConditionsInfo) => {
  return [
    {
      key: '1',
      label: 'Next Year',
      children: <p className='bold'>{`${((marketConditionInfo?.forecastedAnnualValueChangeNextYear * 100).toPrecision(3))}%`}</p>,
    }
  ];
}

export const Statistics = (marketConditionInfo: MarketConditionsInfo) => {
  return [
    {
      title: 'Radius',
      description: marketConditionInfo?.radiusMiles ? marketConditionInfo.radiusMiles.toFixed(1) : null
    },
    {
      title: 'Subdivision', 
      description: marketConditionInfo?.subdivisionName || null
    },
    {
      title: 'Neighborhood',
      description: marketConditionInfo?.neighborhoodName || null
    },
    {
      title: 'Community',
      description: marketConditionInfo?.communityName || null
    }
  ];
}

export const HomePriceIndex = (marketConditionInfo: MarketConditionsInfo) => {
  return [
    {
      key: '1',
      label: 'MSA',
      children: <p className='bold'>{marketConditionInfo?.msaName}</p>,
    }
  ];
}