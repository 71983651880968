import { List } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatNumber, fullAddress } from '../../shared/shared-functions';
import { ExtendedPropertySummaryProps } from '../../types/PropertySummary.def';
import "./PropertyItemConcise.css"

const PropertyItemConcise: React.FC<ExtendedPropertySummaryProps> = (props) => {
    const { property } = props;

    const address = fullAddress(property?.address, property?.city, property?.stateAbbrev, property?.zipCode);

    const navigate = useNavigate();

    return (
        <List.Item style={{padding:0}} className='concise'>
            <List.Item.Meta 
            title={
                <span style={{ fontSize:".75rem", cursor: 'pointer'}} onClick={() => navigate(`/property-search/view/${property?.id}`)}>
                    {address}
                </span>
            }
            description={
                <span style={{ fontSize:".75rem"}}>
                    {property?.listPrice ? `List Price: $${formatNumber(property?.listPrice)}` : "Price: N/A"} | &nbsp; 
                    {property?.finishedLivingSqFt ? `${property?.finishedLivingSqFt} sq.ft.` : "Sq. ft.: N/A"} | &nbsp;
                    {property?.acres ? `${Math.round(property?.acres * 100) / 100} acres` : "Acres: N/A"} | &nbsp;
                    {property?.bedrooms ? `${Math.round(property?.acres * 100) / 100} BDS` : "BDS: N/A"} | &nbsp;
                    {property?.bathrooms ? `${Math.round(property?.acres * 100) / 100} BA` : "Ba: N/A"};
                </span>
            }
            />
        </List.Item>
    );
};

export default PropertyItemConcise;