import React from 'react';
import { PropertyProps } from '../../../types/Property.def';
import { Divider, Descriptions } from 'antd';
import { OwnershipInfo, MortgageInfo, LoanToValue, ListPriceToValue } from './PropertyOwnershipData';

const PropertyOwnership: React.FC<PropertyProps> = (props) => {

    const { property } = props;

    const ownershipItems = OwnershipInfo(property);
    const mortgageItems = MortgageInfo(property);
    const loanToValueItems = LoanToValue(property);
    const listPriceToValueItems = ListPriceToValue(property);


    return (
        <div>
            <Divider orientation='left'>Ownership Information</Divider>
            <Descriptions size='small' column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
                bordered items={ownershipItems} />
            <Divider orientation='left'>Mortgage Information</Divider>
            <Descriptions size='small' column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
                bordered items={mortgageItems} />
            <Divider orientation='left'>Loan to Value</Divider>
            <Descriptions size='small' column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
                bordered items={loanToValueItems} />
            <Divider orientation='left'>List Price to Value</Divider>
            <Descriptions size='small' column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
                bordered items={listPriceToValueItems} />
        </div>
    );
};

export default PropertyOwnership;