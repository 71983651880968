import React from 'react';
import { PropertyProps } from '../../../types/Property.def';
import { Descriptions, Divider, Typography } from 'antd';
import { propertyCharacteristicsData, propertyListingInformation } from './PropertyCharacteristicsData';
import { fullAddress } from '../../../shared/shared-functions';

const PropertyCharacteristics: React.FC<PropertyProps> = (props) => {

    const { property } = props;

    const [rows, setRows] = React.useState(3);
    const [expanded, setExpanded] = React.useState(false);

    const address = [{
        key: '1',
        label: 'Address',
        children: <p className='bold'>{fullAddress(property?.address, property?.city, property?.stateAbbrev, property?.zipCode)}</p>,
    }]

    const characteristics = propertyCharacteristicsData(property);
    const info = propertyListingInformation(property);

    return (
        <div>
            <Divider orientation='left'>Property Characteristics</Divider>
            <Descriptions size='small' column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
                bordered items={address} />
            <br></br>
            <Descriptions size='small' column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
                bordered items={characteristics} />
            <Divider orientation='left'>Listing Information</Divider>
            <Descriptions size='small' column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }}
                bordered items={info} />
            <Divider orientation='left'>Remarks</Divider>
            <Typography.Paragraph
                className='transition'
                ellipsis={{
                    rows,
                    expandable: 'collapsible',
                    expanded,
                    onExpand: (_, info) => setExpanded(info.expanded),
                }}
            >
                {property?.remarks_listing || "N/A"}
            </Typography.Paragraph>

        </div>
    );
};

export default PropertyCharacteristics;