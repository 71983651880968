import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export const dateFormat: string = "MM/DD/YY";

export const toast_info = (message: string) =>  { toast(message, {type: "info", toastId: message + "_INFO", autoClose: 3000}) };

export const toast_error = (message: string) =>  { toast(message, {type: "error", toastId: message + "_ERROR", autoClose: 3000}) };

export const toast_success = (message: string) =>  { toast(message, {type: "success", toastId: message, autoClose: 3000}) };

export const toast_warn = (message: string) =>  { toast(message, {type: "warning", toastId: message, autoClose: 3000}) };

export const numberWithCommas = (x: number | null, decimalPlaces: number = 0) => {
    if(!x) return "N/A";
    let num = x.toFixed(decimalPlaces);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatNumber = (num: string | number | null) => {
    if(!num) return null;
    if( typeof num !== 'number') return num;
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + 'B';
    } else if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + 'K';
    } else {
        return num.toString();
    }
};



export const formatPercent = (num) => {
    return (num * 100).toFixed(2) + "%"
}

export const formatDate = (dateString: string, includeTime: boolean = true) : string => {
    if(!dateString) return "N/A";
    const date = new Date(dateString.replace(" ", "T"));

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        ...(includeTime && {
            hour: '2-digit',
            minute: '2-digit', 
            second: '2-digit',
            hour12: true
        })
    };

    return date.toLocaleString('en-US', options);
}

export const ageInYearsFromString = (dateString: string) => {
    if(!dateString) return null;
    const date = new Date(dateString.replace(" ", "T"));
    const today = new Date();
    const age = today.getFullYear() - date.getFullYear();
    return age;
}

export const ageInYearsFromYear = (year: number) => {
    const today = new Date();
    const age = today.getFullYear() - year;
    return age;
}

export const camelCaseToNormalString = (str: string): string => {
    return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
              .replace(/^./, str => str.toUpperCase());
};


const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

export const checkValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

export const validatePassword = (password: string, confirmPassword: string): boolean => {
    if (!password || !confirmPassword) {
        toast_error("Password cannot be empty.");
    } else if (password !== confirmPassword) {
        toast_error("Make sure your passwords match.");
        return false;
    } else if (password?.length < 8) {
        toast_error("Your password must be at least 8 characters long.");
        return false;
    } else if (password?.length > 64) {
        toast_error("Your password must be less than 64 characters long.");
        return false;
    } else {
        return true;
    }
}

export const surroundUrlsWithLink = (text) => {
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
        if (part.match(urlRegex)) {
            return <Link key={index} to={part}>{part}</Link>;
        } else {
            return part;
        }
    });
};

export const fullAddress = (street: string, city: string, state: string, zip: string) => {
    if(!street && !city && !state && !zip) return "N/A";
    let parts: string[] = [];
    if(street) parts.push(street);
    if(city) parts.push(city);
    if(state) parts.push(state);
    if(zip) parts.push(zip);
    return parts.join(", ");
}

export const googleMapsUrl = (street: string, city: string, state: string, zip: string) => {
    return `https://www.google.com/maps/place/${street}+${city}+${state}+${zip}`
}

export const REAUTH_IGNORE_PATHS: string[] = [
    "/",
    "/login",
    "/property-search"
]