import React from 'react';
import { List, Typography, Statistic, Tooltip } from 'antd';
import { ValuationListData } from '../../../types/PropertyValuationsTypes.def';
import './ValuationList.css';

 
const ValuationList: React.FC<ValuationListProps> = ({data}) => {

    if(!!!data)
        return(<></>);
    const {rows: content, header} = data

    const toolTipItem = (label: string, info: string) => {
        return (
            <Tooltip placement="right" title={info}>
                <u style={{ cursor: 'pointer' }}>{label}</u>
            </Tooltip>
        )
    }
    return (
        <List 
            header={
                !!!header ? null :
                <div className="valuations-grid">
                    {header.map((element) => <Typography.Text>{element}</Typography.Text>)}
                </div>
            }
            dataSource={content}
            renderItem={
                (item) => (
                    <List.Item>
                        <div className="valuations-grid" style={{gridTemplateColumns: `repeat(${data.columnCount || header?.length || 'auto-fit'}, minmax(90px, 1fr))`}}>
                        <Typography.Text>
                            {!!item.info ? toolTipItem(item.label, item.info) : item.label}
                        </Typography.Text>
                        {
                            item?.values.map(({value, suffix}) => {
                                return <Statistic valueStyle={{ fontSize: 15 }} className="valuation-list-statistic" value={ value } prefix={ value === "-" || suffix ? null : '$'} suffix={suffix} precision={suffix ? 2 : 0} />
                            })
                        }
                        </div>
                    </List.Item>
                )
            }
        />
    );
};

interface ValuationListProps {
    data: ValuationListData;
}

export default ValuationList;