import React from 'react';
import { Table, Divider, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { MarketConditionsDetailsWithDisplay } from '../../types/MarketConditions.def';
import { formatNumber } from '../../shared/shared-functions';

interface Props {
    marketConditions: MarketConditionsDetailsWithDisplay;
}

const MarketConditionDetail: React.FC<Props> = ({ marketConditions }) => {
    const getTrendColor = (trend: string | null | undefined): string => {
        if (!trend) return 'default';
        switch (trend.toLowerCase()) {
            case 'stable':
                return 'success';
            case 'improving':
                return 'cyan';
            case 'worsening':
                return 'volcano';
            default:
                return 'default';
        }
    };

    const formatValue = (value: string | undefined | null, type: 'price' | 'percent' | 'number'): string => {
        if (!value || value === '-') return '-';
        
        switch (type) {
            case 'price':
                return `$${formatNumber(value)}`;
            case 'percent':
                return `${value}%`;
            default:
                return value;
        }
    };

    const columns: ColumnsType<any> = [
        {
            title: '',  // Left header cell
            dataIndex: 'metric',
            key: 'metric',
            width: '30%',
        },
        {
            title: 'Prior 7-12 Months',
            dataIndex: 'prior712',
            key: 'prior712',
        },
        {
            title: 'Prior 4-6 Months',
            dataIndex: 'prior46',
            key: 'prior46',
        },
        {
            title: 'Current - 3 Months',
            dataIndex: 'current3',
            key: 'current3',
        },
        {
            title: 'Overall Trend',
            dataIndex: 'trend',
            key: 'trend',
            render: (trend: string) => (
                <Tag color={getTrendColor(trend)}>
                    {trend || '-'}
                </Tag>
            ),
        },
    ];

    // Table 1: Inventory Analysis
    const inventoryData = [
        {
            key: 'sales',
            metric: 'Total # of Comparable Sales (Settled)',
            prior712: formatValue(marketConditions.sales7to12monthsDisplay?.stringOrBlank, 'number'),
            prior46: formatValue(marketConditions.sales4to6monthsDisplay?.stringOrBlank, 'number'),
            current3: formatValue(marketConditions.sales0to3monthsDisplay?.stringOrBlank, 'number'),
            trend: marketConditions.salesTrend || '-',
        },
        {
            key: 'absorption',
            metric: 'Absorption Rate (Total Sales / Months)',
            prior712: formatValue(marketConditions.absorption7to12monthsDisplay?.stringOrBlank, 'number'),
            prior46: formatValue(marketConditions.absorption4to6monthsDisplay?.stringOrBlank, 'number'),
            current3: formatValue(marketConditions.absorption0to3monthsDisplay?.stringOrBlank, 'number'),
            trend: marketConditions.absorptionTrend || '-',
        },
        {
            key: 'actives',
            metric: 'Total # of Comparable Active Listings',
            prior712: marketConditions.actives7to12monthsDisplay?.stringOrBlank || '-',
            prior46: marketConditions.actives4to6monthsDisplay?.stringOrBlank || '-',
            current3: marketConditions.actives0to3monthsDisplay?.stringOrBlank || '-',
            trend: marketConditions.activesTrend || '-',
        },
        {
            key: 'monthsSupply',
            metric: 'Months of Housing Supply (Total Listings / Ab. Rate)',
            prior712: marketConditions.monthsOfSupply7to12monthsDisplay?.stringOrBlank || '-',
            prior46: marketConditions.monthsOfSupply4to6monthsDisplay?.stringOrBlank || '-',
            current3: marketConditions.monthsOfSupply0to3monthsDisplay?.stringOrBlank || '-',
            trend: marketConditions.monthsOfSupplyTrend || '-',
        },
    ];

    // Table 2: Median Stats
    const medianStatsData = [
        {
            key: 'salePrice',
            metric: 'Median Comparable Sale Price',
            prior712: formatValue(marketConditions.medianSalePrice7to12monthsDisplay?.stringOrBlank, 'price'),
            prior46: formatValue(marketConditions.medianSalePrice4to6monthsDisplay?.stringOrBlank, 'price'),
            current3: formatValue(marketConditions.medianSalePrice0to3monthsDisplay?.stringOrBlank, 'price'),
            trend: marketConditions.medianSalePriceTrend || '-',
        },
        {
            key: 'saleDom',
            metric: 'Median Comparable Sales Days on Market',
            prior712: formatValue(marketConditions.medianSaleDom7to12monthsDisplay?.stringOrBlank, 'number'),
            prior46: formatValue(marketConditions.medianSaleDom4to6monthsDisplay?.stringOrBlank, 'number'),
            current3: formatValue(marketConditions.medianSaleDom0to3monthsDisplay?.stringOrBlank, 'number'),
            trend: marketConditions.medianSaleDomTrend || '-',
        },
        {
            key: 'listPrice',
            metric: 'Median Comparable List Price',
            prior712: formatValue(marketConditions.medianListPrice7to12monthsDisplay?.stringOrBlank, 'price'),
            prior46: formatValue(marketConditions.medianListPrice4to6monthsDisplay?.stringOrBlank, 'price'),
            current3: formatValue(marketConditions.medianListPrice0to3monthsDisplay?.stringOrBlank, 'price'),
            trend: marketConditions.medianListPriceTrend || '-',
        },
        {
            key: 'listingDom',
            metric: 'Median Comparable Listings Days on Market',
            prior712: marketConditions.medianListingDom7to12monthsDisplay?.stringOrBlank || '-',
            prior46: marketConditions.medianListingDom4to6monthsDisplay?.stringOrBlank || '-',
            current3: marketConditions.medianListingDom0to3monthsDisplay?.stringOrBlank || '-',
            trend: marketConditions.medianListingDomTrend || '-',
        },
        {
            key: 'saleListPercent',
            metric: 'Median Sale Price as % of List Price',
            prior712: formatValue(marketConditions.medianSaleOverList7to12monthsDisplay?.stringOrBlank, 'percent'),
            prior46: formatValue(marketConditions.medianSaleOverList4to6monthsDisplay?.stringOrBlank, 'percent'),
            current3: formatValue(marketConditions.medianSaleOverList0to3monthsDisplay?.stringOrBlank, 'percent'),
            trend: marketConditions.medianSaleOverListTrend || '-',
        },
    ];

    // Table 3: Concessions
    const concessionsData = [
        {
            key: 'percentWithConcessions',
            metric: '% of Sales with Concessions',
            prior712: formatValue(marketConditions.percentWithConcessions7to12monthsDisplay?.stringOrBlank, 'percent'),
            prior46: formatValue(marketConditions.percentWithConcessions4to6monthsDisplay?.stringOrBlank, 'percent'),
            current3: formatValue(marketConditions.percentWithConcessions0to3monthsDisplay?.stringOrBlank, 'percent'),
            trend: marketConditions.percentWithConcessionsTrend || '-',
        },
        {
            key: 'medianConcessionPercent',
            metric: 'Median Concessions as % of Sale',
            prior712: formatValue(marketConditions.medianConcessionPercentage7to12monthsDisplay?.stringOrBlank, 'percent'),
            prior46: formatValue(marketConditions.medianConcessionPercentage4to6monthsDisplay?.stringOrBlank, 'percent'),
            current3: formatValue(marketConditions.medianConcessionPercentage0to3monthsDisplay?.stringOrBlank, 'percent'),
            trend: marketConditions.medianConcessionPercentageTrend || '-',
        },
    ];

    // Table 4: Foreclosures
    const foreclosuresData = [
        {
            key: 'foreclosures',
            metric: '% Foreclosure (REO) Sales',
            prior712: formatValue(marketConditions.percentForeclosureSales7to12monthsDisplay?.stringOrBlank, 'percent'),
            prior46: formatValue(marketConditions.percentForeclosureSales4to6monthsDisplay?.stringOrBlank, 'percent'),
            current3: formatValue(marketConditions.percentForeclosureSales0to3monthsDisplay?.stringOrBlank, 'percent'),
            trend: marketConditions.percentForeclosureSalesTrend || '-',
        },
    ];

    return (
        <div>
            <Divider orientation="left">Inventory Analysis</Divider>
            <Table 
                columns={columns} 
                dataSource={inventoryData} 
                pagination={false}
            />
            
            <Divider orientation="left">Median Sale & List Price, DOM, Sale/List %</Divider>
            <Table 
                columns={columns} 
                dataSource={medianStatsData} 
                pagination={false}
            />
            
            <Divider orientation="left">Concessions</Divider>
            <Table 
                columns={columns} 
                dataSource={concessionsData} 
                pagination={false}
            />
            
            <Divider orientation="left">Foreclosures</Divider>
            <Table 
                columns={columns} 
                dataSource={foreclosuresData} 
                pagination={false}
            />
        </div>
    );
};

export default MarketConditionDetail;
