import { Typography } from "antd";
import { Property } from "../../../types/Property.def";
import { formatNumber, formatPercent } from "../../../shared/shared-functions";


export const OwnershipInfo = (property: Property) => {
    return [
        {
          key: '1',
          label: 'Owner Name',
          children: <Typography.Text className="bold">{property?.owner || 'N/A'}</Typography.Text>,
        },
        {
          key: '2',
          label: 'Owner Address',
          children: <Typography.Text className="bold">{property?.ownerAddress || 'N/A'}</Typography.Text>,
        },
      ];
}

export const MortgageInfo = (property: Property) => {
    return [
        {
          key: '1',
          label: 'Est. Loan Date',
          children: <Typography.Text className="bold">{new Date(property?.origLoanDate).toLocaleDateString() || 'N/A'}</Typography.Text>, //  why is the est. loan date one day off for granite meadow (and another one i checked. One day behind)
        },
        {
          key: '2',
          label: 'Est. Loan Amount',
          children: <Typography.Text className="bold">{'$' + formatNumber(property?.origLoanAmt) || 'N/A'}</Typography.Text>,
        },
      ];
}

export const LoanToValue = (property: Property) => {
    return [
        {
          key: '1',
          label: 'Est. Loan Balance',
          children: <Typography.Text className="bold">{'$' + formatNumber(property?.currentLoanBalance) || 'N/A'}</Typography.Text>,
        },
        {
          key: '2',
          label: 'Est. Value',
          children: <Typography.Text className="bold">{'$' + formatNumber(property?.valuation) || 'N/A'}</Typography.Text>,
        },
        {
          key: '3',
          label: 'LVT',
          children: <Typography.Text className="bold">{formatPercent(property?.loanToValue) || 'N/A'}</Typography.Text>, 
        },
      ];
}

export const ListPriceToValue = (property: Property) => {
    return [
        {
          key: '1',
          label: 'List Price',
          children: <Typography.Text className="bold">{'$' + formatNumber(property?.listPrice) || 'N/A'}</Typography.Text>, // TODO: empty on old trueAVM... should this check if its an active listing or not?
        },
        {
          key: '2',
          label: 'Est. Value',
          children: <Typography.Text className="bold">{'$' + formatNumber(property?.valuation) || 'N/A'}</Typography.Text>,
        },
        {
          key: '3',
          label: 'LPV',
          children: <Typography.Text className="bold">{formatPercent(property?.listPriceToValue) || 'N/A'}</Typography.Text>,
        },
      ];
}