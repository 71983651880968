import { HomeTwoTone } from '@ant-design/icons';
import { Avatar, List, Tag } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatNumber, fullAddress } from '../../shared/shared-functions';
import { ExtendedPropertySummaryProps } from '../../types/PropertySummary.def';

const PropertyItem: React.FC<ExtendedPropertySummaryProps> = (props) => {
    const { property } = props;

    const address = fullAddress(property?.address, property?.city, property?.stateAbbrev, property?.zipCode);

    const navigate = useNavigate();

    return (
        <List.Item>
            <List.Item.Meta 
            style={{width: 'auto', minWidth: 350, maxWidth: 425}}
            avatar={property?.primaryPhotoUrl ? (<Avatar style={{cursor: 'pointer'}} onClick={() => navigate(`/property-search/view/${property?.id}`)} shape='square' size={64} src={property?.primaryPhotoUrl} />) : <HomeTwoTone style={{fontSize: 64}}/>}
            title={
                <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate(`/property-search/view/${property?.id}`)}>
                    {address}
                </span>
            }
                description={
                    <span>
                        {property?.listPrice ? `$${formatNumber(property?.listPrice)}` : "List Price: N/A"} | &nbsp; 
                        {property?.finishedLivingSqFt ? `${property?.finishedLivingSqFt} sq.ft.` : "Sq. ft.: N/A"} | &nbsp;
                        {property?.acres ? `${Math.round(property?.acres * 100) / 100} acres` : "Acres: N/A"} &nbsp;
                    </span>
                }
            />
            <List.Item.Meta style={{maxWidth: 100}} description={ 
                <span style={{display: 'grid', placeItems: 'center'}}>
                    <Tag style={{ marginLeft: '-24%' }} color={property?.listStatus === "Active" || property?.listStatus === "Auction" ? "success" : "warning"}>
                        {property.listStatus || "Inactive"}
                    </Tag>
                </span>               
            }/>
            {
                window.innerWidth > 768 &&
                    (
                        <List.Item.Meta description={property?.remarks || "--"} />
                    )
            }
        </List.Item>
    );
};

export default PropertyItem;