

export interface RestResponse {
    success: boolean;
    message: string;
    data?: any;
}

export const emptyRestResponse = () => {
    return {
        success: false,
        message: 'No Data',
        data: {}
    } as RestResponse;
}