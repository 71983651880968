import { Row, Col, Image, Typography, Divider } from 'antd';
import { Property } from '../../../types/Property.def';
import { MarketConditionsInfo } from '../../../types/MarketConditionsInfo.def';
import { fullAddress } from '../../../shared/shared-functions';
import MarketConditions from '../MarketConditions/MarketConditions';
import PropertyCharacteristics from '../PropertyCharacteristics/PropertyCharacteristics';
import PropertyOwnership from '../PropertyOwnership/PropertyOwnership';
import PropertyValuations from '../PropertyValuations/PropertyValuations';
import { ExtendedPropertySummary } from '../../../types/PropertySummary.def';
import PropertyList from '../../PropertyList/PropertyList';


const PrintView: React.FC<PrintViewProps> = (props) => {

    const { property, marketConditionsInfo, similarSold, similarForSale, similarNearBy} = props;

    return (
        <>
            <Row>
                <Col span={6}>
                    <Image
                        style={{ borderRadius:'5px', maxHeight: 225, objectFit: 'cover' }}
                        alt="Property Photo"
                        src={property?.primaryPhotoUrl}
                        fallback="../../../img/missing-img.png"
                    />
                </Col>
                <Col span={1}/>
                <Col span={17}>
                    <Typography.Title level={2} style={{ color: '#001529' }}>
                        {fullAddress(property?.address, property?.city, property?.stateAbbrev, property?.zipCode)}
                    </Typography.Title>
                </Col>
            </Row>
            <Typography.Title level={4} style={{marginBottom:"0px"}}>Listing / Characteristics</Typography.Title>
            <Divider style={{border:"1px solid #000000", marginTop:"0px"}}></Divider>
            <PropertyCharacteristics property={property}/>
            <Typography.Title level={4} style={{marginBottom:"0px"}}>Valuations</Typography.Title>
            <Divider style={{border:"1px solid #000000", marginTop:"0px"}}></Divider>
            <PropertyValuations property={property} printView={true}/>
            <Typography.Title level={4} style={{marginBottom:"0px"}}>Ownership / Title</Typography.Title>
            <Divider style={{border:"1px solid #000000", marginTop:"0px"}}></Divider>
            <PropertyOwnership property={property}/>
            <Typography.Title level={4} style={{marginBottom:"0px"}}>Market Conditions</Typography.Title>
            <Divider style={{border:"1px solid #000000", marginTop:"0px"}}></Divider>
            <MarketConditions marketConditionsInfo={marketConditionsInfo}/>
            {!!similarSold || similarForSale || similarNearBy ?
                <>
                    <Typography.Title level={4} style={{marginBottom:"0px"}}>Similar Properties</Typography.Title>
                    <Divider style={{border:"1px solid #000000", marginTop:"0px"}}></Divider>
                </>
                : <></>
            }
            {!!similarSold ?
                <>
                    <Divider orientation='left'>Recently Sold</Divider>
                    <PropertyList results={similarSold} concise />
                </>
                : <></>
            }
            {!!similarForSale ?
                <>
                    <Divider orientation='left'>For Sale</Divider>
                    <PropertyList results={similarForSale} concise={true}/>
                </>
                : <></>
            }
            {!!similarNearBy ?
                <>
                    <Divider orientation='left'>Neighboring Properties</Divider>
                    <PropertyList results={similarNearBy} concise={true} />
                </>
                : <></>
            }
        </>
    )
}

interface PrintViewProps {
    property: Property,
    marketConditionsInfo: MarketConditionsInfo,
    similarSold: ExtendedPropertySummary[],
    similarForSale: ExtendedPropertySummary[],
    similarNearBy: ExtendedPropertySummary[]
}

export default PrintView;
