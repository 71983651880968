import { createContext, useContext, useState } from 'react';
import { toast_warn } from '../shared/shared-functions';
import { useNavigate } from 'react-router-dom';
import { useHttpService } from '../hooks/UseHttpService';

const AppContext = createContext(null);


export const AppProvider = ({ children }) => {
  const navigate = useNavigate();
  const http = useHttpService();
  const storage = localStorage;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    return storage.getItem('isLoggedIn') === 'true';
  });

  const setPersistedIsLoggedIn = (value: boolean) => {
    setIsLoggedIn(value);
    storage.setItem('isLoggedIn', value.toString());
  };

  const redirectIfNoUser = () => {
    if (!isLoggedIn) {
      navigate("/login");
      setTimeout(() => {
        toast_warn("Please log in to access this page.");
      }, 1000);
    }
  }

  const logout = async () => {
    const success = await http.serverLogout();
    storage.clear();
    setIsLoggedIn(false);
    navigate('/login');
    if (!success) {
     console.error("failed to log out from server");
    }
  }

  const value: { isLoggedIn: boolean, setIsLoggedIn: (isLoggedIn: boolean) => void, redirectIfNoUser: () => void, logout: () => void} = {
    isLoggedIn: isLoggedIn,
    setIsLoggedIn: setPersistedIsLoggedIn,
    redirectIfNoUser: redirectIfNoUser,
    logout: logout
  }

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () : {isLoggedIn: boolean, setIsLoggedIn: (isLoggedIn: boolean) => void, redirectIfNoUser: () => void, logout: () => void} => {
  return useContext(AppContext);
};