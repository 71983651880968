import { Flex, Menu, Space } from "antd";
import { Link, Outlet } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useAppContext } from "../../context/AppContext";


export default function Manage() {

    const items = [
        { label: 'Account', key: '/manage/account' },
        // { label: 'Upload a File', key: '/manage/file-upload' },
      ]

      const { isLoggedIn, redirectIfNoUser } = useAppContext();

      useEffect(() => {
        redirectIfNoUser();
      }, [isLoggedIn]);

    return (
        <>
            <Flex wrap>
                <Menu
                    overflowedIndicator={<MenuOutlined style={{ fontSize: 20 }} />}
                    style={{ maxWidth: 200, borderRadius: "10px", margin: "1rem", overflow: 'auto' }}
                    mode="inline"
                    defaultSelectedKeys={['/manage/account']}
                >
                    {items.map(item => {
                        return <Menu.Item key={item.key}><Link to={item.key}>{item.label}</Link></Menu.Item>
                    })}
                </Menu>
                <Space direction="vertical">
                    <Outlet></Outlet>
                </Space>
            </Flex>
        </>
    );
}
