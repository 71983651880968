import { ValuationListData, ValuationMethodsDataType, ValuationByFeaturesDataType, ValuationBySoldCompsDataType, ValuationByActiveListingsDataType } from "../../../types/PropertyValuationsTypes.def"

export const ValuationMethodsInfo = (data: ValuationMethodsDataType): ValuationListData => {
    return {
        header: [
            "Valuation Methods",
            "Current Value",
            "Confidence"
        ],
        rows: [
            {
                label: "Valuation by Features (Potential)",
                info: "Estimated value based upon a comparison of this property's features relative to the sales of other comparable properties.",
                values: [{ value: data?.currentValueByFeatures || "-" }]
            },
            {
                label: "Adjusted Auditor Valuation",
                info: "Estimated value based upon a comparison of this property's tax value relative to the sales of other comparable properties.",
                values: [{ value: data?.currentValueByAuditor || "-" }]
            },
            {
                label: "Adjusted Sale Valuation",
                info: "Estimated value based upon a comparison of this property's features relative to the sales of other comparable properties.",
                values: [{ value: data?.currentValueBySales || "-" }]
            },
            {
                label: "Valuation by Sold Comps",
                values: [{ value: data?.currentValueBySoldComps || "-" }]
            },
            {
                label: "Valuation by Active Listings",
                values: [{ value: data?.currentValueByBestListings || "-" }]
            },
            {
                label: "Estimated Value",
                values: [
                    { value: data?.currentValue || "-" },
                    { value: 100 * data?.confidence || "-", suffix: "%" }
                ]
            }
        ]
    };
};

export const ValuationMethodsSource = (data: ValuationMethodsDataType): ValuationListData => {
    return {
        header: [
            "Data Source",
            "Acres",
            "Sq Ft"
        ],
        rows: [
            {
                label: "Parcel",
                values: [{ value: data?.parcelAcres || "-", suffix : " "}, {value: data?.parcelSqFt || "-", suffix : " "}]
            },
            {
                label: "Listing",
                values: [{ value: data?.listingAcres || "-", suffix : " "}, {value: data?.listingSqFt || "-", suffix : " "}]
            },
            {
                label: "Sale",
                values: [{ value: data?.saleAcres || "-", suffix : " "}, {value: data?.saleSqFt || "-", suffix : " " }]
            }
        ]
    };
};

export const ValuationByFeaturesInfo = (data: ValuationByFeaturesDataType): ValuationListData => {
    return {
        rows: [
            {
                label: "Valuation by Features Together",
                info: "Estimated value based upon a comparison of this property's features relative to the sales of other comparable properties.",
                values: [{ value: data?.currentValueByJoinedFeatures || "-" }]
            },
            {
                label: "Regressed Value of Land",
                info: "Estimated value based upon a comparison of this property's land relative to the sales of other comparable properties.",
                values: [{ value: data?.currentLandValue || "-" }]
            },
            {
                label: "Regressed Value of Improvements",
                info: "Estimated value based upon a comparison of this property's improvements relative to the sales of other comparable properties.",
                values: [{ value: data?.currentImprovementsValue || "-" }]
            },
            {
                label: "Valuation by Features",
                values: [{ value: data?.currentValueByFeatures || "-" }]
            }
        ]
    };
};

export const ValuationBySoldCompsInfo = (data: ValuationBySoldCompsDataType): ValuationListData => {
    return {
        rows: [
            {
                label: "Valuation of Features Together by Sold Comps",
                values: [{ value: data?.valuationSoldsTogether || "-" }]
            },
            {
                label: "Valuation of Land by Sold Comps",
                values: [{ value: data?.valuationOfLandBySolds || "-" }]
            },
            {
                label: "Valuation of Improvements by Sold Comps",
                values: [{ value: data?.valuationOfImprovementsBySolds || "-" }]
            },
            {
                label: "Valuation by Sold Comps",
                values: [{ value: data?.valuationBySolds || "-" }]
            }
        ]
    };
};

export const ValuationByActiveListingsInfo = (data: ValuationByActiveListingsDataType): ValuationListData => {
    return {
        rows: [
            {
                label: "Valuation of Features",
                values: [{ value: data?.valuationActivesTogether || "-" }]
            },
            {
                label: "Valuation of Land",
                values: [{ value: data?.valuationActivesLand || "-" }]
            },
            {
                label: "Valuation of Improvements",
                values: [{ value: data?.valuationActivesImprovements || "-" }]
            },
            {
                label: "Valuation",
                values: [{ value: data?.valuationActives || "-" }]
            }
        ]
    };
};