import { Card, Flex } from 'antd';
import Meta from 'antd/es/card/Meta';
import React from 'react';

//TODO: make this a more custom flex-based layout instead of a card. This looks dumb

const NotFound: React.FC = () => (
    <Flex justify="space-around" align="center">
      <Card
        style={{ width: 300 }}
        cover={
          <img
            alt="example"
            src="../../../img/not-found.png"
          />
        }
      >
      <Meta
      title="Error: 404"
      description="Route not found."
    />
      </Card>
    </Flex>
);

export default NotFound;