import { Button, List, Typography, Modal } from 'antd';
import React, { useState } from 'react';
import { useHttpService } from '../../../hooks/UseHttpService';
import { MarketConditionInfoProps } from '../../../types/MarketConditionsInfo.def';
import { Forcast, HomePriceIndex, Statistics } from './MarketConditionsData';
import { MarketConditionsDetails } from '../../../types/MarketConditions.def';
import { RightOutlined } from '@ant-design/icons';
import MarketConditionDetail from '../../MarketConditionDetail/MarketConditionDetail';
const MarketConditions: React.FC<MarketConditionInfoProps> = (props) => {

    const { marketConditionsInfo: marketConditionInfo } = props;

    const http = useHttpService()

    const forcast = Forcast(marketConditionInfo);
    const statistics = Statistics(marketConditionInfo);
    const homePriceIndex = HomePriceIndex(marketConditionInfo);

    const [loading, setLoading] = useState(false);
    const [marketConditions, setMarketConditions] = useState<MarketConditionsDetails>();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const renderListItem = (item: any) => (
        <List.Item>
            <Typography.Text>{item.label || item.title}</Typography.Text>
            <Typography.Text>{item.children || item.description}</Typography.Text>
        </List.Item>
    );

    const renderExplorableListItem = (item: any) => {
        let value = item.children || item.description;
        let title = item.label || item.title;
        return (
            <List.Item>
                <Typography.Text>{title}</Typography.Text>
                {!!value ? (
                    <Button type='link' icon={<RightOutlined />} iconPosition='end' onClick={() => fetchData(title)}>{value}</Button>
                ) : (
                    <Typography.Text style={{marginRight: '3%'}}>N/A</Typography.Text>
                )}
            </List.Item>
        )
    };

    const fetchData = async (type: string) => {
        type = type.toLowerCase();
        setLoading(true);
        let url = `${type}/marketConditionDetail`;
        switch(type.toLowerCase()) {
            case 'radius': {
                url += `?propertyId=${marketConditionInfo.propertyId}`;
                url = url.replace(type, 'property');
                break;
            } case 'subdivision': {
                url += `?subdivisionId=${marketConditionInfo.subdivisionId}`;
                break;
            } case 'neighborhood': {
                url += `?neighborhoodId=${marketConditionInfo.neighborhoodId}`;
                break;
            } case 'community': {
                url += `?mlsAreaId=${marketConditionInfo.communityId}`;
                url = url.replace(type, 'mlsArea');
                break;
            } default: {
                break;
            }
        }
        http.get(url).then(res => {
            setMarketConditions(res.data);
            setIsModalOpen(true);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
        setMarketConditions(undefined);
    };

    return (
        <div>
            <List
                itemLayout="horizontal"
                header={<Typography.Text style={{fontWeight: 'bold'}}>Forcasted Annual Value Change</Typography.Text>}
                dataSource={forcast}
                renderItem={renderListItem}
            />
            <List
                itemLayout="horizontal"
                header={<Typography.Text style={{fontWeight: 'bold'}}>Statistics</Typography.Text>}
                dataSource={statistics}
                renderItem={renderExplorableListItem}
            />
            <List
                itemLayout="horizontal"
                header={<Typography.Text style={{fontWeight: 'bold'}}>Home Price Index</Typography.Text>}
                dataSource={homePriceIndex}
                renderItem={renderListItem}
            />
            <Modal
                title="Market Conditions"
                open={isModalOpen}
                onCancel={handleModalClose}
                footer={null}
                width="80%"
                style={{ maxWidth: '1000px', minWidth: '350px' }}
                styles={{
                    body: {
                        maxHeight: 'calc(100vh - 200px)',
                        overflowY: 'auto'
                    }
                }}
            >
                {/* <pre>{JSON.stringify(marketConditions, null, 2)}</pre> */}
                <MarketConditionDetail marketConditions={marketConditions} />
            </Modal>
        </div>
    );
};

export default MarketConditions;