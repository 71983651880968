import { MenuOutlined } from "@ant-design/icons";
import { Input, Layout, Menu, Modal, Select, Space } from 'antd';
import type { SearchProps } from 'antd/es/input/Search';
import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useAppContext } from "../../context/AppContext";
import './BaseLayout.css';

const { Header, Content, Footer } = Layout;
const { Search } = Input;
const { Option } = Select;


const BaseLayout: React.FC = () => {

  const { isLoggedIn, logout } = useAppContext();
  const navigate = useNavigate();

  const [searchType, setSearchType] = useState('address');
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const items = [
    { label: 'Property Search', key: '/property-search' },
    { label: 'Value Estimator', key: '/value-estimator' },
    { label: 'Manage', key: '/manage/account' },
    isLoggedIn ? { label: "Log Out", key: "/logout" } : { label: "Log In", key: "/login"},
  ]

  const prefix = (
    <Space>
      <Select defaultValue="address" className="search-type-select" value={searchType} onChange={(value) => setSearchType(value)}>
        <Option value="address">Address</Option>
        <Option value="listNumber">List #</Option>
        <Option value="owner">Owner</Option>
        <Option value="taxId">Tax ID</Option>
        <Option value="text">Text</Option>
      </Select>
    </Space>
  );

  

  const onSearch: SearchProps['onSearch'] = async (value) => {
    //todo: navigate to property search and pass in the search type somehow
    if(value.length > 3) {
      let url = "/property-search?key=" + searchType + "&value=" + value;
      navigate(url);
    }
  }

  const showModal = () => {
    setIsLogoutModalOpen(true);
  }

  const handleLogout = () => {
    logout();
    setIsLogoutModalOpen(false);
  };

  const handleCancelLogout = () => {
    setIsLogoutModalOpen(false);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', padding: '0 25px', background: '#edf6ff', borderBottom: '1px solid #DFE8F0 '}} >
        <div style={{marginRight: 15 }}>
          <Link to="/home"><img src="../../../trueavm.svg" style={iconStyle} alt="trueavm logo" /></Link>
        </div>
        <div style={{minWidth: "50%", maxWidth: "66%"}}>
          <Search addonBefore={prefix} placeholder="Search..." allowClear onClear={() => (window.location.pathname === '/property-search') ? navigate("/property-search") : null } onSearch={onSearch} loading={loading} enterButton style={{ paddingTop: 15, marginRight: 5, width: 'minmax(180, 100%)'}}/>
        </div>
        <Menu
          overflowedIndicator={<MenuOutlined style={{fontSize: 20}} />}
          mode="horizontal"
          defaultSelectedKeys={['2']}
          style={{ flex: 1, minWidth: 0, background: "#edf6ff" }}>
          {items.map(item => {
            if (item.key === "/logout")
              return <Menu.Item key={item.key} onClick={showModal}>{item.label}</Menu.Item>
            return <Menu.Item key={item.key}><Link to={item.key}>{item.label}</Link></Menu.Item>
          })}
        </Menu>
        <Modal title="Are you sure you want to log out?" open={isLogoutModalOpen} onOk={handleLogout} onCancel={handleCancelLogout} okText="Yes">
        </Modal>
      </Header>
      <Content style={{ padding: '0 10px' }}>
        <div
          className="content"
          style={{
            marginTop: 2,
            paddingBottom: 10
          }}
        >
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center', padding: '2vw' }}>
        TrueAVM ©{new Date().getFullYear()} Created by TrueAVM, LLC
      </Footer>
    </Layout>
  );
};

const iconStyle = {height: '40px', width: "auto", marginTop: 10, marginLeft: '-15px'}

export default BaseLayout;