//DO NOT COMMIT CHANGES TO THIS FILE

export const LOCALSERVE: boolean = false;

export const REST_BASE_URL: string = LOCALSERVE ? "http://localhost:8080/rest" : "https://api.trueavm.com/rest"; 
//update localserve url based on whatever the API config is

export const BASE_URL: string = LOCALSERVE ? "http://localhost:8080" : "https://api.trueavm.com";


