import { Button, Divider } from 'antd';
import React, { useState } from 'react';
import { PropertySummary } from '../../../types/PropertySummary.def';
import PropertySlider from '../../PropertySlider/PropertySlider';
import { SetSimilarPropsFunction, SimilarPropertyType } from '../../../types/SimilarPropertiesTypes.def';

const SimilarProperties: React.FC<SimilarPropertiesProps> = (props) => {

    const { recentlySold, forSale, neighboring, propertyId, setSimilarProps } = props; 

    const [recentlySoldLoading, setRecentlySoldLoading] = useState<boolean>(false);
    const [forSaleLoading, setForSaleLoading] = useState<boolean>(false);
    const [neighboringPropsLoading, setNeighboringPropsLoading] = useState<boolean>(false);

    const loadRecentlySold = () => {
        setRecentlySoldLoading(true);
        if (setSimilarProps)
            setSimilarProps(SimilarPropertyType.Sold);
        else
            setRecentlySoldLoading(false); 
    }

    const loadForSale = () => {
        setForSaleLoading(true);
        if (setSimilarProps)
            setSimilarProps(SimilarPropertyType.ForSale);
        else
            setForSaleLoading(false); 
    }

    const loadNeighboringProps = () => {
        setNeighboringPropsLoading(true);
        if (setSimilarProps)
            setSimilarProps(SimilarPropertyType.NearBy);
        else
            setNeighboringPropsLoading(false); 
    }

    return (
        <>
            { !!recentlySold ? 
                <PropertySlider
                    emptyMessage="No Properties Right Now" 
                    mainMessage='Recently Sold' 
                    linkMessage='View All Recently Sold'
                    linkURL={`/property-search`}
                    queryParams={{
                        simProp: SimilarPropertyType.Sold,
                        propertyId: propertyId
                    }}
                    properties={recentlySold}
                />
                : 
                <Divider orientation='left'>
                    Recently Sold
                    <Button loading={recentlySoldLoading} type='link' onClick={loadRecentlySold}>
                        Show
                    </Button>
                </Divider>
            }
            { !!forSale ? 
                <PropertySlider
                    emptyMessage="No Properties Right Now" 
                    mainMessage='For Sale' 
                    linkMessage='View All For Sale'
                    linkURL={`/property-search`}
                    queryParams={{
                        simProp: SimilarPropertyType.ForSale,
                        propertyId: propertyId
                    }}
                    properties={forSale}
                />
                : 
                <Divider orientation='left'>
                    For Sale
                    <Button loading={forSaleLoading} type='link' onClick={loadForSale}>
                        Show
                    </Button>
                </Divider>
            }
            { !!neighboring ? 
                <PropertySlider
                    emptyMessage="No Properties Right Now" 
                    mainMessage='Neighboring Properties' 
                    linkMessage='View All Neighboring Properties'
                    linkURL={`/property-search`}
                    queryParams={{
                        simProp: SimilarPropertyType.NearBy,
                        propertyId: propertyId
                    }}
                    properties={neighboring}
                />
                : 
                <Divider orientation='left'>
                    Neighboring Properties
                    <Button loading={neighboringPropsLoading} type='link' onClick={loadNeighboringProps}>
                        Show
                    </Button>
                </Divider>
            }
        </>
    );
};

interface SimilarPropertiesProps {
    recentlySold : PropertySummary[],
    forSale : PropertySummary[],
    neighboring : PropertySummary[],
    propertyId : string,
    setSimilarProps : SetSimilarPropsFunction | null
}

export default SimilarProperties;