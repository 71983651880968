import { FundProjectionScreenOutlined } from '@ant-design/icons';
import { Card, Drawer, Flex, Image, Statistic, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { PropertySummaryProps } from '../../types/PropertySummary.def';

import { useNavigate } from 'react-router-dom';
import ValueEstimator from '../ValueEstimator/ValueEstimator';
import './PropertyCard.css';

const { Text } = Typography;

const PropertyCard: React.FC<PropertySummaryProps> = (props) => {

    // const [valueEstimatorOpen, setValueEstimatorOpen] = useState(false);

    const { property } = props;

    const fullAddress: string = `${property?.address}, ${property?.city}, ${property?.stateAbbrev}`;

    const activeString = property?.isListed ? "Active" : "Inactive";

    const navigate = useNavigate();
    
    const openProperty = () => {
        navigate(`/property-search/view/${property.id}`);
    }

    // const handleValEstClick = (e: React.MouseEvent) => {
    //     e.stopPropagation();
    //     setValueEstimatorOpen(true);
    // }

    // const onValEstClose = () => {
    //     setValueEstimatorOpen(false);
    // }

    return (
        <div >
        <Card
            onClick={openProperty}
            className='property-card'
            hoverable
            style={{ width: '20vw', maxWidth: 300, minWidth: 250, cursor: 'pointer' }}
            cover={
                <Image
                    draggable={false}
                    preview={false}
                    style={{maxHeight: 225, objectFit: 'cover', borderTopLeftRadius: 8, borderTopRightRadius: 8}}
                    src={property?.primaryPhotoUrl}
                    fallback="../../../img/missing-img.png"
                />
            }
            // actions={[
            //     <Tooltip title="Value Estimator" key="valutation">
            //         <FundProjectionScreenOutlined onClick={handleValEstClick}/>
            //     </Tooltip>,
            // ]}
        >
            <Text type='secondary' style={{ fontSize: 13, overflowX: 'hidden', textOverflow: 'ellipsis' }}>{fullAddress.length > 24 ? fullAddress.substring(0, 21) + "..." : fullAddress}</Text>
            <Flex justify='space-between'>
                <Statistic
                    // value={formatNumber(property?.valuation) || "N/A"}
                    value={property?.valuation || "N/A"}
                    precision={0}
                    prefix={!!property?.valuation ? "$" : ""}
                    suffix={<div style={{fontSize: 10}} className='light-text'>est.</div>}
                />
                <Tag color={property?.isListed ? "success" : "warning"} style={{ alignItems: 'center', height: 25, marginTop: '2.5%' }}>
                    {activeString}
                </Tag>
            </Flex>

        </Card>
        {/* <Drawer
            title="Value Estimator"
            placement="left"
            width={300}
            style={{overflowY: 'scroll'}}
            onClose={onValEstClose}
            open={valueEstimatorOpen}
        >
            <ValueEstimator />
        </Drawer> */}
        </div>
    );
}

export default PropertyCard;