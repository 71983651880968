import './PropertyTextSearch.css';
import { useState } from 'react';
import { Card, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Search } = Input;


export default function PropertyTextSearch() {
    const [searchMap, setSearchMap] = useState<{[key: string]: string}>({});
    const navigate = useNavigate();

    const getSearchText = (key: string) => searchMap[key] || "";

    const handleSearch = (key: string, value: string) => {
        if (!value) return;
        navigate(`/property-search?key=address&value=${encodeURIComponent(value)}`);
    };

    return (
        // <span style={{width: '100%'}}>
        <div style={{display: 'grid', placeItems: 'center', width: '100%'}}>
            <Card style={{width: '85%'}}>
                <Search
                    placeholder="Search by address..."
                    value={getSearchText('address')}
                    onChange={(e) => setSearchMap({...searchMap, address: e.target.value})}
                    onSearch={(value) => handleSearch('address', value)}
                    style={{ width: '100%' }}
                    enterButton
                    className='padding'
                />
                <Search
                    placeholder="Search by list number..."
                    value={getSearchText('listNumber')}
                    onChange={(e) => setSearchMap({...searchMap, listNumber: e.target.value})}
                    onSearch={(value) => handleSearch('listNumber', value)}
                    style={{ width: '100%', marginTop: 8 }}
                    enterButton
                    className='padding'
                />
                <Search
                    placeholder="Search by owner..."
                    value={getSearchText('owner')}
                    onChange={(e) => setSearchMap({...searchMap, owner: e.target.value})}
                    onSearch={(value) => handleSearch('owner', value)}
                    style={{ width: '100%', marginTop: 8 }}
                    enterButton
                    className='padding'
                />
                <Search
                    placeholder="Search by tax ID..."
                    value={getSearchText('taxId')}
                    onChange={(e) => setSearchMap({...searchMap, taxId: e.target.value})}
                    onSearch={(value) => handleSearch('taxId', value)}
                    style={{ width: '100%', marginTop: 8 }}
                    enterButton
                    className='padding'
                />
                <Search
                    placeholder="Search by text..."
                    value={getSearchText('text')}
                    onChange={(e) => setSearchMap({...searchMap, text: e.target.value})}
                    onSearch={(value) => handleSearch('text', value)}
                    style={{ width: '100%', marginTop: 8 }}
                    enterButton
                    className='padding'
                />
            </Card>
        </div>

        // </span>
    );
}
