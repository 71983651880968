import { RightOutlined } from "@ant-design/icons";
import { Button, Descriptions, Divider, Drawer, Flex, List, Modal, Spin, Statistic, Table, Tooltip, Typography, Form, Input, Collapse } from 'antd';
import React, { useState } from 'react';
import { useHttpService } from '../../../hooks/UseHttpService';
import { ageInYearsFromYear, formatDate, toast_error } from '../../../shared/shared-functions';
import { Property } from "../../../types/Property.def";
import { BestCompsParams, DEFAULT_BEST_COMPS_PARAMS, PropertyValuationProps, ValuationByActiveListingsDataType, ValuationByFeaturesDataType, ValuationBySoldCompsDataType, ValuationListData, ValuationMethodsDataType, ValuationType } from "../../../types/PropertyValuationsTypes.def";
import './PropertyValuations.css';
import { bestCompsData, propertyValuationsData, saleHistoryData, taxableValueData } from './PropertyValuationsData';
import { ValuationByActiveListingsInfo, ValuationByFeaturesInfo, ValuationBySoldCompsInfo, ValuationMethodsInfo, ValuationMethodsSource } from './ValuationDrillDownInfo';
import ValuationList from "./ValuationList";
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";

 
const PropertyValuations: React.FC<PropertyValuationProps> = ({property, printView = false}) => {

    const http = useHttpService();
    const navigate = useNavigate();

    const data = propertyValuationsData(property);
    const saleHistory = saleHistoryData(property);
    const bestCompsInfo= bestCompsData(property, ()=>loadValuationData(ValuationType.BestComps));
    const taxableValue= taxableValueData(property);

    const [open, setOpen] = useState<boolean>(false);
    const [drawerLoading, setDrawerLoading] = useState<boolean>(false);
    const [drillDownInfo, setDrillDownInfo] = useState<ValuationListData>(null);
    const [drillDownExtraInfo, setDrillDownExtraInfo] = useState<ValuationListData>(null);

    const [valuationMethodsData, setValuationMethodsData] = useState<ValuationListData>(null);
    const [valuationMethodsSource, setValuationMethodsSource] = useState<ValuationListData>(null);
    const [valuationByFeaturesData, setValuationByFeaturesData] = useState<ValuationListData>(null);
    const [valuationBySoldCompsData, setValuationBySoldCompsData] = useState<ValuationListData>(null);
    const [valuationByActiveListingsData, setValuationByActiveListingsData] = useState<ValuationListData>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [bestCompsList, setBestCompsList] = useState<Property[]>([]);
    const [bestCompsLoading, setBestCompsLoading] = useState<boolean>(false);
    const [params, setParams] = useState<BestCompsParams>(DEFAULT_BEST_COMPS_PARAMS());

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const openModal = async () => {
        setIsModalOpen(true);
        await fetchBestCompsData();
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const fetchBestCompsData = () => {
        setBestCompsLoading(true);
        let url = `/property/valuationByBestComps?maxComps=${params.maxComps}`
        url += `&milesRange=${params.milesRange}&maxMonthsBack=${params.maxMonthsBack}`
        url += `&sqFtPercentRange=${params.sqFtPercentRange}&acresPercentRange=${params.acresPercentRange}`
        url += `&yearBuiltRange=${params.yearBuiltRange}&limit=${params.limit}&propertyId=${property.id}`;
        
        http.get(url).then(res => {
            setBestCompsList(res.data.comps);
        }).catch(error => {
            console.error('Error fetching comps:', error);
            setBestCompsList([]);
        }).finally(() => {
            setBestCompsLoading(false);
        })
    }

    const handleAddressClick = (id: number) => {
        handleModalClose();
        navigate(`/property-search/view/${id}`);
    }

    const fetchValuationData = async <T,>(valType: ValuationType) : Promise<T | null> => {
        return await http.get(`/property/${valType}?propertyId=${property.id}`).then(res =>{
            if (res.success)
                return res.data as T;
            else
                toast_error(res.message);
            return null;
        })
    };

    const loadValuationData = async (valType : ValuationType) => {
        if (valType == ValuationType.BestComps) {
            openModal();
            return;
        }
        showDrawer();
        setDrawerLoading(true);
        setDrillDownExtraInfo(null);
        switch (valType) {
            case ValuationType.ValuationMethods:
                if (!!!valuationMethodsData) {
                    const rawData = await fetchValuationData<ValuationMethodsDataType>(valType);
                    const data = ValuationMethodsInfo(rawData);
                    const data2 = ValuationMethodsSource(rawData);
                    setValuationMethodsData(data);
                    setDrillDownInfo(data);
                    setValuationMethodsSource(data2);
                    setDrillDownExtraInfo(data2)
                }
                else
                    setDrillDownInfo(valuationMethodsData);
                break;
            case ValuationType.Features:
                if (!!!valuationByFeaturesData) {
                    const data = ValuationByFeaturesInfo(await fetchValuationData<ValuationByFeaturesDataType>(valType));
                    setValuationByFeaturesData(data);
                    setDrillDownInfo(data);
                }
                else
                    setDrillDownInfo(valuationByFeaturesData);
                break;
            case ValuationType.SoldComps:
                if (!!!valuationBySoldCompsData) {
                    const data = ValuationBySoldCompsInfo(await fetchValuationData<ValuationBySoldCompsDataType>(valType));
                    setValuationBySoldCompsData(data);
                    setDrillDownInfo(data);
                }
                else
                    setDrillDownInfo(valuationBySoldCompsData);
                break;
            case ValuationType.Active:
                if (!!!valuationByActiveListingsData) {
                    const data = ValuationByActiveListingsInfo(await fetchValuationData<ValuationByActiveListingsDataType>(valType));
                    setValuationByActiveListingsData(data);
                    setDrillDownInfo(data);
                }
                else
                    setDrillDownInfo(valuationBySoldCompsData);
                break;
            // case ValuationType.BestComps:
            //     break;
        }
        setDrawerLoading(false);
    }

    const toolTipItem = (item) => {
        if(item.info) {
            return (
                <>
                    {item.title} 
                    <Tooltip placement="right" title={item.info}>
                        <InfoCircleOutlined style={{marginLeft: 5}}/>
                    </Tooltip>
                </>
            )
        } else {
            return <p style={{margin:"0px"}}>{item.title}</p>
        }
    }

    return (
        <>
            <Flex wrap>
                <div className="printFormatting" style={{width:"100%"}}>
                    <Divider orientation='left'>Component Valuations</Divider>
                    <List
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item style={{padding: 8}}>
                                <div className='valuations-grid'>
                                    <Typography.Text>
                                        {toolTipItem(item)}
                                    </Typography.Text>
                                    <Button onClick={() => loadValuationData(item.type)} size='small' type='link' >
                                        <Statistic valueStyle={{ fontSize: 15 }} value={item.content} prefix={item.suffix || item.content === "-" ? null : "$"} suffix={item.suffix} precision={item.precision || 0} /> <RightOutlined />
                                    </Button>
                                </div>
                            </List.Item>
                        )}
                    />
                    <br></br>
                    <div style={{ textAlign: 'center' }}>
                        { printView ? <></> : <Button type='link' size='small' onClick={() =>console.log("NEED TO WRITE ADJUST CHARACTERISTICS")}>Adjust Characteristics</Button> }
                    </div>
                </div>
            </Flex>
            <Flex wrap>
            <div className="printFormatting" style={{width:"100%"}}>
                    <Divider orientation='left'>Custom Valuation</Divider>
                    <Descriptions size='small' column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }}
                        bordered items={bestCompsInfo} />
                    <Divider orientation='left'>Taxable Value (From Assessor)</Divider>
                    <Descriptions layout="vertical" size='small' column={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 }}
                        bordered items={taxableValue} />
                    <Divider orientation='left'>Sale History</Divider>
                    <List
                        dataSource={saleHistory}
                        renderItem={(item) => (
                            <List.Item style={{padding: 8}}>
                                <div className='valuations-grid'>
                                    <Typography.Text>
                                        {item.date}
                                    </Typography.Text>
                                    <Statistic valueStyle={{ fontSize: 15 }} value={ item.content } prefix={ item.content === "-" ? null : "$"} suffix={null} precision={0} />
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            </Flex>
            <Drawer width={400} title="Valuation Details" maskClosable onClose={onClose} open={open} loading={drawerLoading} >
                <ValuationList data={drillDownInfo} />
                <br/>
                <ValuationList data={drillDownExtraInfo} />
            </Drawer>
            <Modal
                title={
                    <>
                        <Typography.Text style={{fontSize: 18}}>Best Comps</Typography.Text>
                        <Collapse defaultActiveKey={[]} style={{marginBottom: 16, marginTop: 16}}>
                            <Collapse.Panel header="Parameters" key="1">
                                <Flex wrap="wrap" gap="small" style={{ marginBottom: 16 }}>
                                    <div>
                                        <Typography.Text>Max Comps:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.maxComps}
                                            onChange={(e) => setParams({ ...params, maxComps: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Miles Range:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.milesRange}
                                            onChange={(e) => setParams({ ...params, milesRange: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Max Months Back:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.maxMonthsBack}
                                            onChange={(e) => setParams({ ...params, maxMonthsBack: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Sq Ft % Range:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.sqFtPercentRange}
                                            onChange={(e) => setParams({ ...params, sqFtPercentRange: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Acres % Range:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.acresPercentRange}
                                            onChange={(e) => setParams({ ...params, acresPercentRange: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Year Built Range:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.yearBuiltRange}
                                            onChange={(e) => setParams({ ...params, yearBuiltRange: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <Button type="primary" onClick={fetchBestCompsData} style={{ marginLeft: 8, alignSelf: 'flex-end' }}>
                                        Recalculate
                                    </Button>
                                </Flex>
                            </Collapse.Panel>
                        </Collapse>
                    </>
                }
                open={isModalOpen}
                onCancel={handleModalClose}
                footer={null}
                width="80%"
                style={{ maxWidth: '1000px', minWidth: '350px' }}
                styles={{
                    header: {
                        paddingBottom: 0,
                    },
                    mask: {
                        position: 'fixed'
                    },
                    wrapper: {
                        position: 'fixed'
                    }
                }}
            >
                <div style={{display: 'grid', placeItems: 'center', width: '100%'}}>
                    {bestCompsLoading ? <Spin /> : (!bestCompsList || bestCompsList.length === 0) ? (
                        <div>No comps found</div>
                    ) : (
                        <Table 
                            dataSource={bestCompsList} 
                            rowKey={(record) => record.id}
                            columns={[
                                {
                                    title: 'Address',
                                    dataIndex: 'address',
                                    key: 'address',
                                    render: (value, record) => <Button type='link' onClick={() => handleAddressClick(record.id)}>{value}</Button>
                                },
                                {
                                    title: 'Sale Date',
                                    dataIndex: 'saleDate',
                                    key: 'saleDate',
                                    render: (value) => formatDate(value, false)
                                },
                                {
                                    title: 'Est. Sale Amount',
                                    dataIndex: 'estSaleAmt',
                                    key: 'estSaleAmt',
                                    render: (value) => value ? `$${value.toLocaleString()}` : '-'
                                },
                                {
                                    title: 'Est. Sale Amount/Sq Ft',
                                    key: 'estSaleAmtPerSqFt',
                                    render: (_, record) => {
                                        if (record.estSaleAmt && record.finishedLivingSqFt) {
                                            const perSqFt = record.estSaleAmt / record.finishedLivingSqFt;
                                            return `$${perSqFt.toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
                                        }
                                        return '-';
                                    }
                                },
                                {
                                    title: 'Acres',
                                    dataIndex: 'acres',
                                    key: 'acres',
                                    render: (value) => value?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || '-'
                                },
                                {
                                    title: 'Bedrooms',
                                    dataIndex: 'bedrooms',
                                    key: 'bedrooms',
                                },
                                {
                                    title: 'Bathrooms',
                                    dataIndex: 'bathrooms',
                                    key: 'bathrooms',
                                },
                                {
                                    title: 'Age',
                                    key: 'age',
                                    render: (_, record) => {
                                        if (record.yearBuilt) {
                                            return ageInYearsFromYear(record.yearBuilt) + " years";
                                        }
                                        return '-';
                                    }
                                }
                            ]} 
                        />
                    )}

                </div>
            </Modal>
        </>
    );
};

export default PropertyValuations;