import { Button, Form, Input, Typography } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttpService } from '../../hooks/UseHttpService';
import { toast_error, toast_success } from '../../shared/shared-functions';
import axios from 'axios';
import { Header } from 'antd/es/layout/layout';
import { LeftOutlined } from '@ant-design/icons';
import { BASE_URL } from '../../util/constants';


const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
      await axios
        .get(`${BASE_URL}/sendPasswordReset?emailAddress=${values.email}`)
        .then(res => {
            if (res.data.success) {
              toast_success('Password reset instructions have been sent to your email');
              navigate('/login');
            } else {
              toast_error(res.data.message || 'Failed to send reset instructions');
            }
        })
        .catch(err => {
          toast_error('An error occurred while processing your request');
        })
        .finally(() => {
          setLoading(false);
        });
    }

  return (
    <>
            <Header style={{ background: 'transparent' }}>
                <LeftOutlined 
                    onClick={() => navigate(-1)} 
                    style={{ 
                        fontSize: '20px', 
                        cursor: 'pointer',
                        color: 'rgba(0, 0, 0, 0.85)'
                    }} 
                />
            </Header>
        <div style={{ maxWidth: 400, margin: '40px auto', padding: '0 20px' }}>
        <Typography.Title level={2} style={{ textAlign: 'center' }}>
            Reset Password
        </Typography.Title>

        <Typography.Text style={{ display: 'block', marginBottom: 24, textAlign: 'left' }}>
            Enter your email address below and we'll send you instructions on how to reset your password.
        </Typography.Text>

        <Form
            name="reset-password"
            onFinish={onFinish}
            layout="vertical"
        >
            <Form.Item
            name="email"
            label="Email Address"
            rules={[
                { required: true, message: 'Please input your email address!' },
                { type: 'email', message: 'Please enter a valid email address!' }
            ]}
            >
            <Input />
            </Form.Item>

            <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
                Send Reset Request
            </Button>
            </Form.Item>
        </Form>
        </div>
    </>
  );
};

export default ResetPassword;
