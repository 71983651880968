import { Typography } from "antd"
import { Property } from "../../../types/Property.def"
import { ValuationType } from "../../../types/PropertyValuationsTypes.def"
import { numberWithCommas } from "../../../shared/shared-functions"

export const propertyValuationsData = (property: Property) => {
    return [
        // {
        //     title: 'Estimated Value',
        //     content: property?.valuation || "-",
        //     type: ValuationType.ValuationMethods
        // },
        {
            title: "Features",
            content: property?.valuationFeatures || "-",
            info: "Valuation based on this property's features.",
            type: ValuationType.Features
        },
        {
            title: "Adj. Auditor",
            content: property?.valuationAdjAuditor || "-",
            info: "Based on this property's auditor valuation."
        },
        {
            title: "Adj. Prior Sale",
            content: property?.valuationAdjPriorSale || "-",
            info: "Based on this property's prior sales."
        },
        {
            title: "Sold Comps",
            content: property?.valuationSolds || "-",
            info: "Based on data from the sale of similar properties.",
            type: ValuationType.SoldComps
        },
        {
            title: "Active Listings",
            content: property?.valuationActives || "-",
            info: "Based on comparable properties currently for sale.",
            type: ValuationType.Active
        },
        {
            title: "Confidence",
            content: property?.confidence * 100 || "-",
            suffix: property?.confidence ? "%" : "",
            precision: 2,
            info: "A measure of data availability and the similarity of valuations by different methods."
        },
    ]
}

export const saleHistoryData = (property: Property) => {
    return property?.propertySales?.map((sale) => {
        return (
            {
                date: new Date(sale.saleDate).toLocaleDateString(),
                content: sale.saleAmount || "-"
            }
        )
    });
}

export const bestCompsData = (property : Property, onClick : ()=>void) => {
    return [
        {
            key: '1',
            label: 'Best Comps', // Whats going on here? Best Comps name, but valuationSoldsSimple is the field?
            children: <Typography.Text className="bold"><a onClick={onClick}>
            {property?.valuationSoldsSimple ? `$${numberWithCommas(Math.round(property.valuationSoldsSimple))}` : "N/A"}</a></Typography.Text>, // TODO: Date off here too?
        }
    ] 
}

export const taxableValueData = (property : Property) => {
    return [
        {
            key: '1',
            label: 'Land',
            children: <Typography.Text className="bold">
            {property?.auditorValuationOfLandGov ? `$${numberWithCommas(Math.round(property.auditorValuationOfLandGov))}` : "N/A"}</Typography.Text>
        },
        {
            key: '2',
            label: 'Building',
            children: <Typography.Text className="bold">
            {property?.auditorValuationOfImprovementsGov ? `$${numberWithCommas(Math.round(property.auditorValuationOfImprovementsGov))}` : "N/A"}</Typography.Text>
        },
        {
            key: '3',
            label: 'Total',
            children: <Typography.Text className="bold">
            {property?.valuationAdjAuditor ? `$${numberWithCommas(Math.round(property.valuationAdjAuditor))}` : "N/A"}</Typography.Text>
        }
    ] 
}