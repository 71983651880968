import { Typography } from "antd";
import { Property } from "../../../types/Property.def";


export const propertyCharacteristicsData = (property: Property) => {
    return [
        {
          key: '1',
          label: 'Property Type',
          children: <Typography.Text className="bold">{property?.propertyTypeId || 'N/A'}</Typography.Text>,
        },
        {
          key: '2',
          label: 'Year Built',
          children: <Typography.Text className="bold">{property?.yearBuilt || 'N/A'}</Typography.Text>,
        },
        {
          key: '3',
          label: 'Sq Ft',
          children: <Typography.Text className="bold">{property?.finishedLivingSqFt || 'N/A'}</Typography.Text>,
        },
        {
          key: '4',
          label: 'Beds',
          children: <Typography.Text className="bold">{property?.bedrooms || 'N/A'}</Typography.Text>,
        },
        {
          key: '5',
          label: 'Baths',
          children: <Typography.Text className="bold">{property?.bathrooms || 'N/A'}</Typography.Text>,
        },
        {
          key: '6',
          label: 'Lot Size',
          children: <Typography.Text className="bold">{property?.acres || 'N/A'}</Typography.Text>,
        },
      ];
}

export const propertyListingInformation = (property: Property) => {
  return [
      {
        key: '1',
        label: 'List Date',
        children: <Typography.Text className="bold">
        {property?.listDate_listing ? new Date(property.listDate_listing).toLocaleDateString() : "N/A"}</Typography.Text>, // TODO: Date off here too?
      },
      {
        key: '2',
        label: 'MLS #',
        children: <Typography.Text className="bold"><a href={property?.listingUrl}>{property?.listNumber || 'N/A'}</a></Typography.Text>, // TODO: check if it is listed?
      },
      {
        key: '3',
        label: 'Source',
        children: <Typography.Text className="bold">{property?.listingService || 'N/A'}</Typography.Text>,
      },
      {
        key: '4',
        label: 'Listing provided by',
        children:<Typography.Text className="bold">{property?.listingOffice || 'N/A'}<br/>{property?.listingAgentName || ''}</Typography.Text>
      },
    ];
}