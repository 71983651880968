// default react component 
import { Empty, List } from 'antd';
import { ExtendedPropertySummary } from '../../types/PropertySummary.def';
import PropertyItem from '../PropertyItem/PropertyItem';
import PropertyItemConcise from '../PropertyItem/PropertyItemConcise';

const PropertyList: React.FC<PropertyListProps> = ({ results, concise = false }) => {
    return (
        <List
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No results found." /> }}
        itemLayout="horizontal"
        dataSource={results}
        renderItem={item =>
        (
            concise ?
            <PropertyItemConcise property={item}/>
            : <PropertyItem property={item}/>
        )
        }
    />
    )
}

export default PropertyList;

interface PropertyListProps {
    results: ExtendedPropertySummary[],
    concise?: boolean
}